import axios from 'axios'
import { auth, envelopes, bisnode } from "@verified/libvf";
import moment from 'moment'
import {en, sv} from 'vuejs-datepicker/dist/locale'

export default {
  name: "app",
  data() {
    return {
			required :true,
			step: 1,
			logo:null,
			lang: "sv_SE",
			loading: true,
			sent: false,
			config: null,
			translations: null,
			methods:null,
			isInfo :  true,
			bisnodeError : false,
			completed : false,
			sv : sv,
			file : null,
			file1 : null,
			file2 : null,
			file3 : null,
			file4 : null,
			file5 : null,
			file6 : null,
			authorizationRequired:false,
			functionList : [],
			relationList :[],
			countriesList: [],
			nationalityList: [],
			ownershipList:["Direkt","Indirekt"],
			influenceList:["Ja","Nej"],
			redirectUrl: "https://ikanobank.se/foretag",
			signingOrder: {
				signParallel : false,
				signSequential: true
			},
			isBisnodeFetched : false,
			disabledStartDates : {
				to: null
			},
			disabledEndDates : {
				to : null,
				from :null
			},
			showInvalidFeedback: {
				"datepicker-emp-start" : false,
				"datepicker-emp-end" : false,
			},
			noFile : false,
			noFile1 : false,
			noFile2 : false,
			noFile3 : false,
			noPassportFile:false,
			passportFiles: [],
			validPrincipalDescriptions : null,
			signature : [],
			ud: {
				isIE : false,
				_isForwarded: false	,
				authorizationRequired:false,
				signingMethod: null,
				signingMethod2: null,
				fileName: null,
				date:null,
				authorizationFiles:[],
				translations: null,
				lang: "sv_SE",
				companyName: null,
				boardMembers : null,
				otherBoardMembers : null,
				address: null,
				zip: null,
				town: null,
				boardMembersList : [],
				questions : [],
				otherBoardMembersList : [],
				turnOverArray : ["0 – 1 000 000 kr", "1 000 001 – 20 000 000 kr", "20 000 001 – 50 000 000 kr", "50 000 001 – 1 000 000 000 kr", "1 000 000 000 kr +"],
				employeesArray : ["0", "1 - 9", "10 - 49", "50 - 249", "250 - 499", "500 +"],

				resourcesArray : ["Ägartillskott","Försäljning av verksamhet","Ackumulerade vinstmedel","Banklån","Annan Källa"],
				resourcesArray1 : ["Medlemsavgifter","Ackumulerade vinstmedel","Banklån", "Bidrag", "Sponsring","Annan Källa"],
				resourcesArray2 : ["Intäkter från verksamhet","Avkastning på investeringar","Försäljning av verksamhet","Annan Källa"],
				resourcesArray3 : ["Ägartillskott","Försäljning av verksamhet","Ackumulerade vinstmedel","Banklån","Finansbolag","Annan Källa"],

				nationalityArray:['Svensk','Annan'],
				inHighRiskCountry:{
					yes: false,
					no: false
				},
				cash:{
					yes: false,
					no: false
				},
				overseasBusiness:{
					yes: false,
					no: false
				},
				realPrincipal:{
					yes: false,
					no: false
				},
				naturalPersons:{
					yes: false,
					no: false
				},
				pepPersons:{
					yes: false,
					no: false
				},
				group:{
					yes: false,
					no: false
				},
				forward:{
					signDirect: false,
					signForward: false
				},
				alternative:{
					alternative1: false,
					alternative2: false
				},
				alternativeOption:{
					alternative1: false,
					alternative2: false,
					alternative3: false
				},
				isOtherAddress : {
					yes : false
				},
				incomingForeignPaymentsCountries:[{country:null}],
				outgoingForeignPaymentsCountries:[{country:null}],
				principal: [],
				persons: [{place: null,
					ssn: null,
					firstName: null,
					lastName: null,
					city: null,
					zip: null,
					address: null,
					nationality:"Svensk",
					mainIncome : {
						incomeOp1 : false,
						incomeOp2 : false,
						incomeOp3 : false,
						incomeOp4 : false
					}}],
				persons1: [{place: null,
					ssn: null,
					firstName: null,
					lastName: null,
					city: null,
					zip: null,
					address: null,
					nationality:"Svensk",
					mainIncome : {
						incomeOp1 : false,
						incomeOp2 : false,
						incomeOp3 : false,
						incomeOp4 : false
					}}],
				legalType : {
				},
				pep:[{
					firstName : null,
					lastName : null,
					ssn : null,
					address : null,
					function : null,
					relation : null
				}],
				noListAddSigner:{
					sign1:false
				},
				org_signing:{
					sign:false
				},
				isLegalForm : false,
				mainIncome : {
					incomeOp1 : false,
					incomeOp2 : false,
					incomeOp3 : false,
					incomeOp4 : false
				},
				businessInOtherCountry : {
					yes : false,
					no : false
				},
				
				form8Info4 : {
					yes : false
				},
				form8Info5 : {
					yes : false
				},
				form9Info4 : {
					yes : false
				},
				
				form1Info4 : {
					yes : false
				},
				form1Info5 : {
					yes : false
				},
				form2Info4 : {
					yes : false
				},
				form2Info5 : {
					yes : false
				},
				form4Info3 : {
					yes : false
				},
				form4Info4 : {
					yes : false,
					no:false
				},
				form4Info7 : {
					yes : false
				},
				form5Info4 : {
					yes : false
				},
				form5Info7 : {
					yes : false
				},
				form5Info9 : {
					yes : false
				},
				form5Info12 : {
					yes : false
				},
				form5Info14 : {
					yes : false
				},
				form6Info15 : {
					yes : false
				},
				form6Info12 : {
					yes : false
				},
				form6Info9 : {
					yes : false
				},
				form6Info7 : {
					yes : false
				},
				form6Info4 : {
					yes : false
				}
			}
		}
	},
	methods: {
		steps() {
			// console.log("in steps ==",this.file== null)

			if ((this.ud.isLegalForm&&this.ud.legalForm=='Enskild firma')||(this.ud.legalType&&(this.ud.legalType.legalFormOp3||this.ud.legalType.legalFormOp10))) {
				return [
				{ title: this.__('step1'),first:true,back : this.__('back') },
				{ title: this.__('step2'),back : this.__('back') },
				{ title: this.__('step3'),back : this.__('back') }
			]
			}else{
				return [
					{ title: this.__('step1'),first:true,back : this.__('back') },
					{ title: this.__('step2'),back : this.__('back') },
					{ title: this.__('step3'),back : this.__('back') },
					{ title: this.__('step4'),back : this.__('back') }
				]
			}
			
		},
		handleFileChange(e) {
			// console.log('file ==', e.target.files[0])
			this.file = e.target.files[0];
			this.noFile = false;
			this.$emit('input', e.target.files[0])
		},
		handleFileChange1(e) {
			// console.log('file ==', e.target.files[0])
			this.file1 = e.target.files[0];
			console.log(this.file1)
			this.noFile = false;
			this.$emit('input', e.target.files[0])
		},
		handleFileChange2(e) {
			// console.log('file ==', e.target.files[0])
			this.file2 = e.target.files[0];
			this.noFile = false;
			this.$emit('input', e.target.files[0])
		},
		handleFileChange3(e) {
			// console.log('file ==', e.target.files[0])
			this.file3 = e.target.files[0];
			this.noFile1 = false;
			this.$emit('input', e.target.files[0])
		},
		handleFileChange4(e) {
			// console.log('file ==', e.target.files[0])
			this.file4 = e.target.files[0];
			this.noFile2 = false;
			this.$emit('input', e.target.files[0])
		},
		handleFileChange5(e) {
			// console.log('file ==', e.target.files[0])
			this.file5 = e.target.files[0];
			this.noFile2 = false;
			this.$emit('input', e.target.files[0])
		},
		handleFileChange6(e,index,persons) {
			this.passportFiles[index] = e.target.files[0]
			this.$emit('input', e.target.files[0])
			if(persons=='persons')
			{
				this.ud.persons[index].noPassportFile = false;
			}else{
				this.ud.persons1[index].noPassportFile = false;
			}
			this.noPassportFile = false
			this.$forceUpdate()
		},

		handleFileChange7(e,index) {
			this.file6 = e.target.files[0];
			this.noFile3 = false;
			this.$emit('input', e.target.files[0])
		},
		handleauthorizationFileChange(e) {
			// console.log('file ==', e.target.files[0])
			this.ud.authorizationFiles.push(e.target.files[0]);
			this.$emit('input', e.target.files[0])
		},
		removeFile(type,index=0){
			if(type=="authorization")
			{
				this.ud.authorizationFiles.splice(index, 1);

			}else if(type=='passports'){
				console.log(index)
				console.log(this.passportFiles[index])
				this.passportFiles[index] = null
				this.ud.persons[index].noPassportFile = true
			}
			else{
				if (index=='1') {
					this.file = null;
				}else if (index=='2') {
					this.file1 = null;
				}else if (index=='3') {
					this.file2 = null;
				}else if (index=='4') {
					this.file3 = null;
				}else if (index=='5') {
					this.file4 = null;
				}else if (index=='6') {
					this.file5 = null;
				}else if (index=='7') {
					this.file6 = null;
				}
				
			}
			this.$forceUpdate()
		},
		isOtherCountry(){
			for(var person in this.ud.persons) {
				if(this.ud.persons[person].nationality&&this.ud.persons[person].nationality!='Svensk')
					return true
			}
			return false
		},
		isOtherCountry1(){
			for(var person in this.ud.persons1) {
				if(this.ud.persons1[person].nationality&&this.ud.persons1[person].nationality!='Svensk')
					return true
			}
			return false
		},
		changeLang(lang){
			// console.log(lang);
			this.ud.lang = lang;
			this.lang = lang;
			this.setIso(this.lang)
		},
		addPersons(){
			this.ud.persons.push(
				{
					place: null,
					ssn: null,
					firstName: null,
					lastName: null,
					city: null,
					zip: null,
					address: null,
					nationality:"Svensk",
					mainIncome : {
						incomeOp1 : false,
						incomeOp2 : false,
						incomeOp3 : false,
						incomeOp4 : false
					}
				});
		},
		addPersons1(){
			this.ud.persons1.push(
				{
					place: null,
					ssn: null,
					firstName: null,
					lastName: null,
					city: null,
					zip: null,
					address: null,
					nationality:"Svensk",
				});
		},
		addFromCountries(){
			this.ud.incomingForeignPaymentsCountries.push(
				{
					country : null
				});
		},
		checkAlternative1(){
			this.ud.form1Info5.yes = false
		},
		checkAlternative2(){
			console.log("check alternative")
			this.ud.form1Info4.yes = false
		},
		msieAlert(){
			console.log("activated")
			if (window.document.documentMode) {
				// Do IE stuff
				this.ud.isIE = true;
				console.log("IE")
			}else  // If another browser, return 0
			{
				console.log('otherbrowser');
			}
		
			return false;
		},
		addToCountries(){
			this.ud.outgoingForeignPaymentsCountries.push(
				{
					country : null
				});
		},
		isDisabledOp1(){
			if (this.ud.form6Info7.yes==true||this.ud.form6Info9.yes==true||this.ud.form6Info15.yes==true||this.ud.form6Info12.yes==true) {
				return true
			}

			return false
		},
		isDisabledOp2(){
			if (this.ud.form6Info4.yes==true||this.ud.form6Info12.yes==true||this.ud.form6Info15.yes==true) {
				return true
			}

			return false
		},
		isDisabledOp3(){
			if (this.ud.form6Info4.yes==true||this.ud.form6Info7.yes==true||this.ud.form6Info9.yes==true) {
				return true
			}

			return false
		},
		nextStep() {
			if(this.authorizationRequired )
			{
				if(this.ud.authorizationFiles.length<1)
				{
					return;
				}
			}else{
				if(!this.validateForm(`form${this.step}`) ) {
					return
				}
			} 
			if (this.step==1) {
				if (this.ud.isLegalForm&&(this.ud.legalForm=='Aktiebolag'||this.ud.legalForm=='Handelsbolag'||this.ud.legalForm=='Kommanditbolag')) {
					if (this.ud.legalType.legalFormOp1||this.ud.legalType.legalFormOp2||this.ud.legalType.legalFormOp8||this.ud.legalType.legalFormOp9) {
						console.log("no op1")
					}else{
						console.log("if op1")
						this.ud.legalType = {
							legalFormOp1 : false,
							legalFormOp2 : false,
							legalFormOp8 : false,
							legalFormOp9 : false
						}
					}
					
				}else if(this.ud.isLegalForm&&this.ud.legalForm=='Enskild firma'){
					if (this.ud.legalType.legalFormOp3||this.ud.legalType.legalFormOp10) {

					}else{
						this.ud.legalType = {
							legalFormOp3 : false,
							legalFormOp10 : false
						}
					}
				}else if(!this.ud.isLegalForm){
					if (this.ud.legalType.legalFormOp4||this.ud.legalType.legalFormOp5||this.ud.legalType.legalFormOp6||this.ud.legalType.legalFormOp7) {

					}else{
						this.ud.legalType = {
							legalFormOp4 : false,
							legalFormOp5 : false,
							legalFormOp6 : false,
							legalFormOp7 : false,
							legalFormOp11 : false,
							legalFormOp12 : false
						}
					}
				}

			}
			if (this.step==2) {
				if (this.ud.businessInOtherCountry&&this.ud.businessInOtherCountry.yes) {
					if (!this.ud.otherCountry||(this.ud.otherCountry&&this.ud.otherCountry.length==0)) {
						return
					}
				}
				
				if (this.ud.legalType&&!this.ud.legalType.legalFormOp3&&!this.ud.legalType.legalFormOp10) {
					if ((this.ud.legalType.legalFormOp1||this.ud.legalType.legalFormOp2||this.ud.legalType.legalFormOp8||this.ud.legalType.legalFormOp9)&&!this.file&&this.ud.legalForm!='Handelsbolag'&&this.ud.legalForm!='Kommanditbolag') {
						this.noFile = true
						return
					}
					if ((this.ud.legalType.legalFormOp5||this.ud.legalType.legalFormOp6)&&!this.file2) {
						this.noFile = true
						return
					}
					if ((this.ud.legalType.legalFormOp4||this.ud.legalType.legalFormOp7)&&!this.file1) {
						this.noFile = true
						return
					}
				}
			}
			if (this.step==3) {
				if (this.ud.legalType.legalFormOp1||this.ud.legalType.legalFormOp2||this.ud.legalType.legalFormOp8||this.ud.legalType.legalFormOp9||this.ud.legalType.legalFormOp11||this.ud.legalType.legalFormOp12) {
						if (this.ud.alternative.alternative1 && !this.ud.persons[0].firstName) {
							return
						}
						if (this.ud.form1Info4.yes==true) {
							if (!this.file3) {
								this.noFile1 = true
								return
							}
							
						}else{
							this.noFile1 = false
						}
				}
				if(this.ud.legalType.legalFormOp5||this.ud.legalType.legalFormOp6){
					// if ((this.ud.form6Info4.yes!=true)&&(this.ud.form6Info7.yes!=true&&this.ud.form6Info9.yes!=true)&&(this.ud.form6Info12.yes!=true&&this.ud.form6Info15.yes!=true)) {
					// 	return
					// }
				}
				if(this.ud.legalType.legalFormOp5||this.ud.legalType.legalFormOp6){
					if ((this.ud.alternativeOption.alternative2 && this.ud.form6Info9.yes) || (this.ud.alternativeOption.alternative3 && this.ud.form6Info15.yes)) {
						if (!this.file5) {
							this.noFile2 = true
							return
						}
						
					}
					if((this.ud.alternativeOption.alternative2 && this.ud.form6Info7.yes) || (this.ud.alternativeOption.alternative3 && this.ud.form6Info12.yes))
					{
						if (!this.file6) {
							this.noFile3 = true
							return
						}
					}
				}
				if(this.ud.legalType.legalFormOp4||this.ud.legalType.legalFormOp7){
					if (this.ud.form4Info4.yes||this.ud.form4Info7.yes) {
						if (this.ud.form4Info4.yes&&!this.ud.persons[0].firstName) {
							return
						}
						
					}
					if(this.ud.form4Info4.no && this.ud.form4Info3.yes){
						if(!this.file6)
						{
							this.noFile3 = true
							return
						}
					}
				}
				if (this.ud.form4Info7.yes) {
					if (!this.file4) {
						this.noFile2 = true
						return
					}
				}
				if(this.ud.form4Info3.yes)
				{
					if (!this.file6) {
						this.noFile3 = true
						return
					}
				}
				if ((this.ud.form1Info4.yes || this.ud.form4Info3.yes || this.ud.form6Info7 )&&this.isOtherCountry()) {
					for(var person in this.ud.persons) {
						if(this.ud.persons[person].nationality && this.ud.persons[person].nationality!='Svensk')
						{
							console.log(this.ud.persons[person].hasOwnProperty('noPassportFile'))
							if(this.ud.persons[person].hasOwnProperty('noPassportFile')){
								if(this.ud.persons[person].noPassportFile)
								{
									this.ud.persons[person].noPassportFile = true
									return
								}
								
							}else{
								this.ud.persons[person].noPassportFile = true
								return
							}
							
							
							
						}
					}
						
				}
				if (this.ud.form6Info12 && this.isOtherCountry1()) {
					for(var person1 in this.ud.persons1) {
						if(this.ud.persons1[person1].nationality && this.ud.persons1[person1].nationality!='Svensk')
						{
							console.log(this.ud.persons1[person1].hasOwnProperty('noPassportFile'))
							if(this.ud.persons1[person1].hasOwnProperty('noPassportFile')){
								if(this.ud.persons1[person1].noPassportFile)
								{
									this.ud.persons1[person1].noPassportFile = true
									return
								}
								
							}else{
								this.ud.persons1[person1].noPassportFile = true
								return
							}
							
						}
					}
						
				}
			}
			this.noFile = false;
			this.noFile1 = false;
			this.noFile2 = false;

			// this.steps()
			// alert(this.step)
			if(this.step >= 4 ||(this.step == 3 &&((this.ud.isLegalForm&&this.ud.legalForm=='Enskild firma')||(this.ud.legalType&&(this.ud.legalType.legalFormOp3||this.ud.legalType.legalFormOp10)))))
			{
				console.log(this.ud.boardMembers)
				if(this.ud.boardMembers=='Jag är inte med i listan men har rätt att signera (fullmakt)' && !this.authorizationRequired)
				{
					this.ud.authorizationRequired = true;
					return this.authorizationRequired=true;
				}
				else{
					console.log("else submit",this.authorizationRequired);
					if(this.authorizationRequired)
					{
						if( this.ud.authorizationFiles.length<0)
						{
							return;
						}
						else{
							return this.submit();
						}
						
					}
					else{
						return this.submit();
					}
					
				}
				
			}
				

			this.step++

		},
		backStep() {
			if(this.authorizationRequired)
			{	this.ud.authorizationRequired = false;
				return this.authorizationRequired=false;
			}
			this.step--
		},
		previousStep(n) {
			this.step = n
		},
		validateForm(form) {
			console.log(this.$refs[form].validate())
			return this.$refs[form].validate()
		},
		setQuestions(){
			// alert("in set qstns");
			var questions = []
			if (this.ud.legalType.legalFormOp1==true) {
				console.log("testtt")
				var q = ["mainIncome","businessTurnOver","businessEmployees","businessFor","useOfIkea","countOfUse","financialResource","businessInOtherCountry","companyAddress","attachFile"]
				questions = questions.concat(q)
			}
			if (this.ud.legalType.legalFormOp2==true) {
				questions = questions.concat(["mainIncome","businessTurnOver","businessEmployees","businessFor","whyChoose","invoiceCount","financialResource","businessInOtherCountry","companyAddress","attachFile"])
			}
			if (this.ud.legalType.legalFormOp8==true) {
				questions = questions.concat(["companyAddress","businessInOtherCountry","mainIncome","businessTurnOver","businessEmployees","businessFor","leasedItemUse","companyFinancialResource","attachFile"])
			}
			if (this.ud.legalType.legalFormOp9==true) {
				questions = questions.concat(["mainIncome","businessTurnOver","businessEmployees","businessFor","businessItem","financialResource","businessInOtherCountry","companyAddress","attachFile"])
			}
			if (this.ud.legalType.legalFormOp3==true) {
				questions = questions.concat(["mainIncome","businessTurnOver","businessEmployees","businessFor","useOfIkea","countOfUse","financialResource","businessInOtherCountry","companyAddress"])
			}
			if (this.ud.legalType.legalFormOp10==true) {
				questions = questions.concat(["companyAddress","businessInOtherCountry","mainIncome","businessTurnOver","businessEmployees","businessFor","leasedItemUse","companyFinancialResource"])
			}
			if (this.ud.legalType.legalFormOp4==true) {
				questions = questions.concat(["mainIncome","associationTurnOver","associationMembers","associationActivities","useOfIkea","countOfUse","associationFinancialResource","businessInOtherCountry","associationAddress","attachMinutes"])
			}
			if (this.ud.legalType.legalFormOp5==true) {
				questions = questions.concat(["mainIncome","foundationTurnOver","foundationEmployees","foundationFor","useOfIkea","countOfUse","foundationFinancialResources","businessInOtherCountry","foundationAddress","attachCertificate"])
			}
			if (this.ud.legalType.legalFormOp6==true) {
				questions = questions.concat(["foundationAddress","businessInOtherCountry","foundationTurnOver","foundationEmployees","foundationFor","leasedItemUse","foundationFinancialResources","mainIncome","attachCertificate"])
			}
			if (this.ud.legalType.legalFormOp7==true) {
				questions = questions.concat(["associationAddress","businessInOtherCountry","associationTurnOver","associationMembers","associationActivities","leasedItemUse","associationFinancialResource","mainIncome","attachMinutes"])
			}

			if (this.ud.legalType.legalFormOp11==true) {
				questions = questions.concat(["mainIncome","businessTurnOver","businessEmployees","businessFor","useOfIkea","countOfUse","financialResource","businessInOtherCountry","companyAddress"])
			}
			if (this.ud.legalType.legalFormOp12==true) {
				questions = questions.concat(["companyAddress","businessInOtherCountry","mainIncome","businessTurnOver","businessEmployees","businessFor","leasedItemUse","companyFinancialResource"])
			}

			console.log(questions)
			var qArray = []
			for (var i = 0; i < questions.length; i++) {
				if (qArray.indexOf(questions[i])==-1) {
					qArray.push(questions[i])
				}
			}
			console.log(qArray)
			this.ud.questions = qArray
		},
		isInQuestions(item){
			if (this.ud.questions.indexOf(item)!= -1) {
				return true
			}
			return false
		},
		async fetchBisnode(){
			var org_nr = this.ud.orgNr.replace(/[\-\s]/g, "");
			if(org_nr.length != 10)
			{
				return;
			}
			try{
				this.isBisnodeFetched = false		
				let data = await bisnode.sweden.getCompanyInfo(org_nr);
				//let user = person.consumer
				console.log(data);		
				this.bisnodeError = false
				this.ud.companyName = data.companyName;
				this.ud.address = data.address;
				this.ud.zip = data.postalCode;
				this.ud.city = data.city;
				var industry = ''
				var i = 0
				for (var item in data.lineOflineOfBusiness){
					if (i==0) {
						industry = industry+ ' ' + data.lineOflineOfBusiness[item]
					}else{
						industry = industry+ ', ' + data.lineOflineOfBusiness[item]
					}
					i++;
				}
				this.ud.industry = industry;
				data.boardMembers = this.trimBoardMembers(data.boardMembers);
				this.ud.boardMembersList =[];
				console.log("ajo1"+typeof this.ud.boardMembersList);
				for (var i = 1; i <= data.boardMembers.length; i++) {
					this.ud.boardMembersList.push(data.boardMembers[(i - 1)].principalName);
				}
				this.ud.boardMembersList.push("Jag är inte med i listan men har rätt att signera (fullmakt)");
				console.log(this.ud.boardMembersList);
				this.ud.signatories = data.boardMembers;
				this.signature = data.signature
				if (data.legalForm) {					
					this.ud.legalForm = data.legalForm;
					this.ud.isLegalForm = true;
				}else{
					this.ud.isLegalForm = false;
					this.ud.legalForm = null
				}
			}catch(ex){
				this.bisnodeError = true;
				console.log(ex)
			}
			this.isBisnodeFetched = true
		},
		async fetchSignatoryBisnode(index){
			var ssn = this.ud.persons[index].ssn.replace(/[\-\s]/g, "");
			if(ssn.length != 12)
			{
				this.ud.persons[index].firstName = null
				this.ud.persons[index].lastName = null
				this.ud.persons[index].zip = null
				this.ud.persons[index].address = null
				this.ud.persons[index].city = null
				this.ud.persons[index].nationality = "Svensk"
				this.ud.persons[index].mainIncome = {
					incomeOp1 : false,
					incomeOp2 : false,
					incomeOp3 : false,
					incomeOp4 : false
				}
				this.$forceUpdate()
				return;
			}
			try{
				let person = await bisnode.sweden.getPersonInfo(ssn);
				let user = person.consumer
				console.log(user)
				this.ud.persons[index].firstName = user.firstName
				this.ud.persons[index].lastName = user.surname
				this.ud.persons[index].zip = user.postCode
				var aptNo = user.postalAdressLine1.split(' ')
				aptNo = aptNo[aptNo.length-1]
				// console.log("aptNo ===",aptNo.length)
				// this.ud.persons[index].place = aptNo
				this.ud.persons[index].address = user.postalAdressLine1
				this.ud.persons[index].city = user.town
				this.$forceUpdate()
			}catch(ex){
				console.log(ex)
			}
		},
		async fetchSignatoryBisnode1(index){
			var ssn = this.ud.persons1[index].ssn.replace(/[\-\s]/g, "");
			if(ssn.length != 12)
			{
				this.ud.persons1[index].firstName = null
				this.ud.persons1[index].lastName = null
				this.ud.persons1[index].zip = null
				this.ud.persons1[index].address = null
				this.ud.persons1[index].city = null
				this.ud.persons1[index].nationality = "Svensk"
				this.ud.persons1[index].mainIncome = {
					incomeOp1 : false,
					incomeOp2 : false,
					incomeOp3 : false,
					incomeOp4 : false
				}
				return;
			}
			try{
				let person = await bisnode.sweden.getPersonInfo(ssn);
				let user = person.consumer
				console.log(user)
				this.ud.persons1[index].firstName = user.firstName
				this.ud.persons1[index].lastName = user.surname
				this.ud.persons1[index].zip = user.postCode
				var aptNo = user.postalAdressLine1.split(' ')
				aptNo = aptNo[aptNo.length-1]
				// console.log("aptNo ===",aptNo.length)
				// this.ud.persons[index].place = aptNo
				this.ud.persons1[index].address = user.postalAdressLine1
				this.ud.persons1[index].city = user.town
				this.$forceUpdate()
			}catch(ex){
				console.log(ex)
			}
		},
		updateBoardMembers() {
			console.log("inside updateBoardMembers");
			this.ud.boardMembers = null;
			this.ud.otherBoardMembers = null;
			this.ud.org_signing.sign = false;
			//this.ud.my_email = null;
	
			this.ud.boardMembersList = [];
			this.isEmpty();
			console.log(this.isEmpty());
	
	
			for (var i = 1; i <= this.ud.signatories.length; i++) {
				this.ud.boardMembersList.push(this.ud.signatories[(i - 1)].principalName);
			}
			if(this.ud.forward.signDirect)
				if (!this.isEmpty()){
					this.ud.boardMembersList.push("Jag är inte med i listan men har rätt att signera (fullmakt)");
				}
	
		},
		isEmpty() {
			//																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																														console.log("inside isEmpty");
			for(var prop in this.ud.boardMembersList) {
				if(this.ud.boardMembersList.hasOwnProperty(prop))
					return false;
	
			}
			return true;
		},
		updateOtherBoardMembers() {
			console.log("inside updateOtherBoardMembersupdateOtherBoardMembers");
			this.ud.otherBoardMembersList =[];
			for (var i = 1; i <= this.ud.signatories.length; i++) {
				this.ud.otherBoardMembersList.push(this.ud.signatories[(i - 1)].principalName);
			}
			const index = this.ud.otherBoardMembersList.indexOf(this.ud.boardMembers);
			if (index > -1) {
				this.ud.otherBoardMembersList.splice(index, 1);
			}
			this.ud.otherBoardMembers = null;
	
		},

		trimBoardMembers(boardMembers) {

			console.log(boardMembers);
			console.log("trimming!");
	
			var validPrincipalDescriptions = [
				"ledamot & vd",
				"vd & ordförande",
				"suppleant & vd",
				"vd & delgivningsbar person",
				"vd",
				"vd, suppleant & delgivningsbar person",
				"extern vd",
				"extern vd & delgivningsbar person",
				"vice vd & ordförande",
				"ledamot vice vd",
				"suppleant & vice vd",
				"vice vd, suppleant & delgivn.bar person",
				"vice vd",
				"vice vd & delgivningsbar person",
				"extern vice vd",
				"extern vice vd & delgivningsbar person",
				"ledamot ordförande",
				"ledamot",
				"ledamot & delgivningsbar person",
				"extern firmatecknare",
				"likvidator",
				"suppleant",
				"ordf, suppleant & delgivningsbar person",
				"suppleant & delgivningsbar person",
				"delgivningsbar person",
				"delägare & prokurist",
				"delägare",
				"komplementär",
				"kommanditdelägare & prokurist",
				"kommanditdelägare",
				"föreståndare",
				"prokurist"
			];
		this.validPrincipalDescriptions = validPrincipalDescriptions
			var trimmed = boardMembers.filter(function(el) {
				return validPrincipalDescriptions.indexOf(el.principalFunctionDesc.toLowerCase()) !== -1;
			});
	
			// before
			console.log(boardMembers);
	
			// after
			console.log(trimmed);
	
			return trimmed;
	
		},
		parseName(nameString) {
			var strings = nameString.split(/,| /);
	
			return {
				"firstName": strings[(strings.length - 1)],
				"lastName": strings[0]
			}
		},
		async generateSecondRecipients(envelope) {
			let promise = [];
			let signingMethod2 = 'bankid-se'
			if (this.ud.signingMethod2 == 'BankID FI E-Ident') {
				signingMethod2 = 'nets-eident-fi'
			}else if (this.ud.signingMethod2 == 'NEMID DK E-Ident') {
				signingMethod2 = 'nets-eident-dk'
			}else if(this.ud.signingMethod2 == 'BANKID NO'){
				signingMethod2 = 'bankid-no'
			}else{
				signingMethod2 = 'bankid-se'
			}
			if(this.ud.noListAddSigner.sign1) {
				promise.push(
				envelope.addRecipient({
					"givenName": this.ud.secondlist_firstName,
					"familyName": this.ud.secondlist_lastName,
					"language": "sv_SE",
					"signingMethod": signingMethod2,
					"email": this.ud.secondlist_email,
					"order": 10,
					"role": {
						"action": "sign",
						"label": "Signer",
						"name": "signer"
					}
				}));
			}
			return Promise.all(promise)
		},
		async addRecipients(envelope){
			let promises = []
			var signingMethod = 'bankid-se'
			if (this.ud.signingMethod == 'BankID FI E-Ident') {
				signingMethod = 'nets-eident-fi'
			}else if (this.ud.signingMethod == 'NEMID DK E-Ident') {
				signingMethod = 'nets-eident-dk'
			}else if(this.ud.signingMethod == 'BANKID NO'){
				signingMethod = 'bankid-no'
			}else{
				signingMethod = 'bankid-se'
			}
			// alert(signingMethod)
			//Add tenant
			// If selected "i'm not in the list"
			if(this.ud.boardMembers=='Jag är inte med i listan men har rätt att signera (fullmakt)' || this.isEmpty()) {
				promises.push(
					envelope.addRecipient({
					"givenName": this.ud.nolist_firstName,
					"familyName": this.ud.nolist_lastName,
					"language": "sv_SE",
					"signingMethod": signingMethod,
					"email": this.ud.nolist_email,
					"order": 2,
					"role": {
						"action": "sign",
						"label": "Signer",
						"name": "signer"
					}
				}));
			} else {
				promises.push(
					envelope.addRecipient({
					"givenName": this.parseName(this.ud.boardMembers).firstName,
					"familyName": this.parseName(this.ud.boardMembers).lastName,
					"language": "sv_SE",
					"signingMethod": signingMethod,
					"email": this.ud.my_email,
					"order": 2,
					"role": {
						"action": "sign",
						"label": "Signer",
						"name": "signer"
					}
				}));
			}

			// If we want to add another person to sign
			if(this.ud.org_signing && this.ud.org_signing.sign) {
				var signingMethod2 = 'bankid-se'
				if (this.ud.signingMethod2 == 'BankID FI E-Ident') {
					signingMethod2 = 'nets-eident-fi'
				}else if (this.ud.signingMethod2 == 'NEMID DK E-Ident') {
					signingMethod2 = 'nets-eident-dk'
				}else if(this.ud.signingMethod2 == 'BANKID NO'){
					signingMethod2 = 'bankid-no'
				}else{
					signingMethod2 = 'bankid-se'
				}

				console.log('signingmethod2 ==',signingMethod2)
				promises.push(
					envelope.addRecipient({
					"givenName": this.parseName(this.ud.otherBoardMembers).firstName,
					"familyName": this.parseName(this.ud.otherBoardMembers).lastName,
					"language": "sv_SE",
					"signingMethod":  signingMethod2,
					"email": this.ud.other_email,
					"order": 3,
					"role": {
						"action": "sign",
						"label": "Signer",
						"name": "signer"
					}
				}))
			}
			return Promise.all(promises)
		},
		getUrlParameter(param, dummyPath) {
			var sPageURL = dummyPath || window.location.search.substring(1),
				sURLVariables = sPageURL.split(/[&||?]/),
				res;
			for (var i = 0; i < sURLVariables.length; i += 1) {
				var paramName = sURLVariables[i],
					sParameterName = (paramName || '').split('=');
				if (sParameterName[0] === param) {
					res = sParameterName[1];
				}
			}
			return res;
		},
		setEmailText(){
			this.ud.notifySignerSubject = this.translations.notifySignerSubject?this.translations.notifySignerSubject[this.ud.lang]:"";
			this.ud.notifySignerBody   	= this.translations.notifySignerBody?this.translations.notifySignerBody[this.ud.lang]:"";
			this.ud.notifySignerButton 	= this.translations.notifySignerButton?this.translations.notifySignerButton[this.ud.lang]:"";
			this.ud.notifySignerInfo    = this.translations.notifySignerInfo?this.translations.notifySignerInfo[this.ud.lang]:"";
			this.ud.notifySignerFooter1 = this.translations.notifySignerFooter1?this.translations.notifySignerFooter1[this.ud.lang]:"";
			this.ud.notifySignerFooter2 = this.translations.notifySignerFooter2?this.translations.notifySignerFooter2[this.ud.lang]:"";
			this.ud.documentSignedSubject = this.translations.documentSignedSubject?this.translations.documentSignedSubject[this.ud.lang]:"";
			this.ud.documentSignedBody = this.translations.documentSignedBody?this.translations.documentSignedBody[this.ud.lang]:"";
			this.ud.documentSignedFooter1 = this.translations.documentSignedFooter1?this.translations.documentSignedFooter1[this.ud.lang]:"";
			this.ud.documentSignedFooter2 = this.translations.documentSignedFooter2?this.translations.documentSignedFooter2[this.ud.lang]:"";
			this.ud.documentSignedButton = this.translations.documentSignedFooter2?this.translations.documentSignedButton[this.ud.lang]:"";
			this.ud.notifySignerFooter1 = this.ud.notifySignerFooter1.replace(/%contactEmail%/g, this.config.mailContactEmail[this.source]);
			this.ud.documentSignedFooter1 = this.ud.notifySignerFooter1.replace(/%contactEmail%/g, this.config.mailContactEmail[this.source]);
			
		},
		async submit() {
			let self = this;
			console.log(JSON.stringify(self.ud))
			// if(!this.validateForm('form1') && !this.validateForm('form2') && !this.validateForm('form3') && !this.validateForm('form4')){
			// 	return
			// }
			this.loading = true
			self.ud.date = new Date().toISOString().slice(0, 10);
			let envelope = null
			if(self.ud.forward.signForward){
				self.ud._isForwarded = true;
			}
			console.log(JSON.stringify(self.ud))
			await envelopes.create('ikano-onboarding-demo').then(function (res) {
				envelope = res;
			}).then(function () {
				return envelope.getBearerToken('/flows/simple-public-flow-with-silent-parties');
			}).then(function (token) {
				return auth.setToken(token);
			}).then(function () {
				return envelope.reflect();
			}).then(function(){
				return envelope.firstTemplate().setUserData(self.ud)
			}).then(function () {
				var name = 'Aktiebok '+self.ud.orgNr
				if(self.file)
					return envelope.firstDocument().uploadAttachment(name, self.file);
				else
					return Promise.resolve();
			}).then(function () {
				var name = 'Styrelseprotokoll '+self.ud.orgNr
				if(self.file1)
					return envelope.firstDocument().uploadAttachment(name, self.file1);
				else
					return Promise.resolve();
			}).then(function () {
				var name = 'Registreringsbevis '+self.ud.orgNr
				if(self.file2)
					return envelope.firstDocument().uploadAttachment(name, self.file2);
				else
					return Promise.resolve();
			}).then(function () {
				if(self.file3)
					return envelope.firstDocument().uploadAttachment(self.file3.name, self.file3);
				else
					return Promise.resolve();
			}).then(function () {
				if(self.file4)
					return envelope.firstDocument().uploadAttachment(self.file4.name, self.file4);
				else
					return Promise.resolve();
			}).then(function () {
				if(self.file5)
					return envelope.firstDocument().uploadAttachment(self.file5.name, self.file5);
				else
					return Promise.resolve();
			}).then(function () {
				if(self.file6)
					return envelope.firstDocument().uploadAttachment(self.file6.name, self.file6);
				else
					return Promise.resolve();
			}).then(function () {
				let promises = []
				if(self.ud.authorizationFiles.length>0){
					self.ud.authorizationFiles.forEach(function(authorizationFile) {
							promises.push(envelope.firstDocument().uploadAttachment(authorizationFile.name, authorizationFile));
						})
						return Promise.all(promises)	
				}
				else
					return Promise.resolve();
			}).then(function () {
				let promises = []
				if(self.passportFiles.length>0){
						self.passportFiles.forEach(function(passportFile) {
							promises.push(envelope.firstDocument().uploadAttachment(passportFile.name, passportFile));
						})
						return Promise.all(promises)	
				}
				else
					return Promise.resolve();

				// if(self.ud.otherFile.length>0){
				// 	var promises = []
				// 	self.ud.otherFile.forEach(function(othFile) {
				// 		var index = self.ud.otherFile.indexOf(othFile);
				// 		promises.push(envelope.firstDocument().uploadAttachment(othFile.name, othFile));
				// 	})
				// 	return Promise.all(promises)	
				// }
				// else
				// 	return Promise.resolve()
			}).then(function () {
				return self.addRecipients(envelope)
			}).then(function () {
				return self.generateSecondRecipients(envelope)
			}).then(function () {
				return envelope.firstDocument().put({ name: self.ud.fileName+' - '+self.ud.companyName+' - '+self.ud.orgNr+'.pdf' });
			}).then(function () {
				console.log(self.ud.legalType)
				var signature_match= false
				
				var silentPartiesArray = []
				if(self.config.silentEmail[self.source]){
						silentPartiesArray.push({
								givenName : self.config.silentFN[self.source]?self.config.silentFN[self.source]:'',
								familyName : self.config.silentLN[self.source]?self.config.silentLN[self.source]:'',
								email : self.config.silentEmail[self.source]
							})

				}
				// if (signature_match) {
				// 	return envelope.put({
				// 		metadata : {
				// 			silentParties : silentPartiesArray,
				// 			signature_match: signature_match,
				// 			board_member_whitelist : self.validPrincipalDescriptions
				// 		}
				// 	})
				// }else{

				return envelope.put({
					metadata : {
						silentParties : silentPartiesArray
					}
				})
				
				
			})
			.then(function () {
				return envelope.publish();
			}).then(function () {
				envelope.reflect();
				if(!self.ud._isForwarded){
					envelope.getSignUrl({redirectTo:self.redirectUrl}) 
					.then(function (url) {
					
					// window.location.href = "https://app.verified.eu/#/sign"+ envelope.data.uid + "?access_token=" + token + "&lang=" + self.lang + "&redirect_to=" + encodeURIComponent(self.redirectUrl);
					setTimeout(()=>{ window.location.href = url}, 6000)
					});
				}else{
					self.loading = false;
					self.completed = true;
					setTimeout(()=>{ window.location.href = self.redirectUrl;}, 9000)
					
				}
				
				
			}).catch(function (err) {
				console.log("ERROR", err);
				self.loading = false;
				throw err;
			});
			

		}
	},
	watch: {
		"ud.form3Info4"() {
			this.$forceUpdate()
		},
		"ud.form4Info4"() {
			this.$forceUpdate()
		},
	},
	async created() {
		// Set language to use
		this.setIso(this.lang)

		const [translations, pdfTranslations, config,nationality,functions,relation,countries] = await Promise.all([
			axios.get('https://sheets.web-services.verified.eu/ikano-onboarding/translations'),
			axios.get('https://sheets.web-services.verified.eu/ikano-onboarding/pdf_translations'),
			axios.get('https://sheets.web-services.verified.eu/ikano-onboarding/config'),
			axios.get('https://sheets.web-services.verified.eu/countries/nationalities'),
			axios.get('https://sheets.web-services.verified.eu/ikano-onboarding/functions'),
			axios.get('https://sheets.web-services.verified.eu/ikano-onboarding/relations'),
			axios.get('countries.json')
		])
		this.methods = [
			{text: 'BANKID SE', value:"bankid-se"},
			{text: 'BANKID NO', value:"bankid-no"},
			{text: 'BankID FI E-Ident', value:"nets-eident-fi"},
			{text: 'NEMID DK E-Ident', value:"nets-eident-dk"},
		]
		var countryList = []
		for (var key in countries.data)
			countryList.push(countries.data[key]);
		countryList.sort();
		console.log(countryList)
		this.countries = countryList
		// This combines veform's default translations with the ones from sheets
		// duplicates are overwritten by the sheet
		this.translations = translations.data
		this.mergeLocale({
			"validation.dob": {
				en_EN: "Please enter a valid date of birth",
				sv_SE: "Var vänlig ange ett giltigt födelsedatum",
				fi_FI: "Anna kelvollinen syntymäaika",
			},
			"validation.zipse": {
				en_EN: "Please enter a valid post number",
				sv_SE: "Var vänlig ange ett giltigt Postnummer",
				fi_FI: "Anna kelvollinen syntymäaika",
			}
		});
		this.mergeLocale(translations.data)
		this.ud.translations = translations.data

		this.nationalityList    = Object.keys(nationality.data);

		for (let key in functions.data) {
			if (key > 0)
			this.functionList.push(functions.data[key].value);
		}
		for (let key in relation.data) {
			if (key > 0)
			this.relationList.push(relation.data[key].value);
		}

		console.log(config.data)
		var source  = this.getUrlParameter("source") || "original";
		this.source = source
		this.config = config.data
		console.log(this.config.token[source])
		document.title = this.config.browserTitle[source];
		this.redirectUrl = this.config.redirectUrl[source];
		this.logo = this.config.logo[source];
		this.ud.fileName = this.config.fileName[source];


		this.disabledStartDates.to = moment().set('hour', 0).set('minute',0).set('second',0).toDate()

		let authOpts = {token:this.config.token[source], namespace:this.config.namespace[source]}
		auth.authenticate(authOpts)
		this.msieAlert()
		this.setEmailText();
		this.loading = false;
	}
}